$taxdebtoffers-why-us-brief: #747474;
$taxdebtoffers-default-link-color: #000;
$taxdebtoffers-default-button-bgcolor: #eb7f58;
$taxdebtoffers-default-button-text-color: #ffffff;
$taxdebtoffers-default-checkbox-selected-bgcolor: #e9f1ff;
$taxdebtoffers-default-progress-color: #bbbbbb;

:export {
    primaryColor: $taxdebtoffers-default-progress-color;
    whyUsBriefColor: $taxdebtoffers-why-us-brief;
    linkColorDefault: $taxdebtoffers-default-link-color;
    buttonBgColorDefault: $taxdebtoffers-default-button-bgcolor;
}
