
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.main-banner {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: flex-start;
    height: auto;
    // height: calc(100vh - 58px);
    min-height: calc(100vh - 58px);
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        background-image: url("/assets/images/mainBgRepeater.svg");
        // background-color: #c4d1eb;
        background-size: 160%;
        background-repeat: repeat-x;
        background-position: bottom center;
        z-index: 0;
        @include lg {
            background-size: 50%;
        }
    }
}
.form {
    z-index: 5;
    width: 100%;
    padding: 30px 24px;
    max-width: 700px;
    border-radius: 10px;
    min-height: 500px;
    background-color: white;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
    position: relative;
    box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 15%);
    @include lg {
        min-width: 400px;
        width: 60%;
        padding: 40px 40px;
        margin-bottom: 200px;
        margin-top: 12vh;
    }
    .steps-inner {
        flex-grow: 1;
    }
    h1 {
        &.step-title {
            font-size: 20px;
            font-weight: bold !important;
            margin: 0;
            @include lg {
                font-size: 30px;
            }
        }
    }
}
.step-title {
    text-align: center;
    font-size: 20px !important;
    font-weight: 700 !important;
    margin-bottom: 10px;
}
.label-as-step-title {
    text-align: center;
    font-size: 20px !important;
    font-weight: 700 !important;
    margin-bottom: 10px;
    margin-top: -20px;
    margin-bottom: 25px !important;
    @include lg {
        margin-left: -50px;
        margin-right: -50px;
    }
}
.step-description {
    text-align: center;
    font-weight: 300;
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-size: 14px;
    @include lg {
        font-size: 18px;
    }
}
.fields-wrapper {
    margin-top: 1.5rem;
}
.form-button {
    max-width: unset !important;
    margin: 0 auto !important;
    margin-top: 5px !important;
    border-radius: 3px !important;
    background-color: $taxdebtoffers-default-button-bgcolor !important;
    transition: all 0.2s ease-in-out;
    @include lg {
        max-width: 450px !important;
    }
    &:hover {
        background-color: $taxdebtoffers-default-button-bgcolor !important;
    }
}
.form-field {
    // margin-top: 2rem;
    max-width: unset;
    margin: 0 auto;
    margin-bottom: 0.5rem;
    @include lg {
        max-width: 450px;
    }
}

.radio-field {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;

    margin-top: 10px;

    & > div {
        width: 100%;
        margin-bottom: 15px;
        display: flex;
        input {
            &:checked + label {
                font-weight: 700;
                background-color: $taxdebtoffers-default-button-bgcolor;
                border-color: $taxdebtoffers-default-button-bgcolor;
                color: $taxdebtoffers-default-button-text-color !important;
            }
        }
        label {
            border-radius: 3px;
            background-color: #fff;
            border: 1px solid $taxdebtoffers-default-button-bgcolor;
            color: #000;
            display: flex !important;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            &:hover {
                background-color: $taxdebtoffers-default-button-bgcolor;
                color: white;
            }
            span {
                font-size: 14px !important;
                @include md {
                    font-size: 16px !important;
                }
            }
            @include md {
                min-height: 50px;
            }
            svg {
                margin-bottom: 10px;
                width: 30px;
                height: auto;
            }
        }
        @include md {
            margin: 0 10px 15px;
            width: 60% !important;
            margin-left: auto;
            margin-right: auto;
        }
        &:last-child {
            margin-bottom: 10px !important;
        }
    }
    @include lg {
        margin: 0 -10px;
    }
}
.checkbox-field {
    & > div {
        width: 100%;
        margin-bottom: 15px;
        display: flex;
        input {
            &:checked + label {
                font-weight: 700;
                background-color: $taxdebtoffers-default-checkbox-selected-bgcolor;
            }
        }
        label {
            border-radius: 3px;
            border: 1px solid $taxdebtoffers-default-button-bgcolor;
            color: #000;
            font-size: 14px;
            display: flex;
            align-items: center;
            & > span:first-child {
                border-radius: 3px;
            }
            @include md {
                min-height: 50px;
            }
        }
    }
}
.tcpa {
    margin-bottom: 10px;
    // width: 80% !important;
    margin: 10px auto !important;
}
.image {
    z-index: 2;
    & > div {
        position: absolute !important;
        left: 0;
        bottom: 0;
        height: 300px;
        width: 100vw;
        max-width: unset !important;
        left: 50% !important;
        transform: translateX(-50%) !important;

        @include lg {
            height: 100%;
            top: 0 !important;
            left: 0%;
        }
    }

    img {
        min-height: unset !important;
        min-width: unset !important;
        width: auto !important;
        height: 100% !important;
        max-width: unset !important;
        margin-left: 0 !important;
        margin-right: auto !important;
    }
}
.step-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 345px;
    &:nth-of-type(1) {
        .form-field > label {
            text-align: center;
            margin-bottom: 13px;
            font-weight: 600;
        }
    }
}
.back-button {
    left: -15px !important;
    bottom: -20px !important;
}
.selling-point {
    font-size: 11px;
    color: #999;
    padding-top: 10px;
}
.progress-bar {
    margin: 30px auto !important;
    // width: 80% !important;
}
.serviceBlockLnk {
    font-size: 12px;
    cursor: pointer;
    border: 1px solid var(--primary-color);
    position: relative;
    padding: 15px;
    box-shadow: 0px 10px 10px -8px rgb(0 0 0 / 15%);
    display: flex;
    justify-content: start;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    text-align: left;
    @include md {
        text-align: center;
        flex-direction: column;
        flex: 0 0 30.6%;
    }
    input {
        position: absolute;
        opacity: 0;
    }
    svg {
        width: 80px;
        height: auto;
        @media screen and (max-width: 423px) {
            width: 45px;
        }
    }
    i {
        width: 15px;
        height: 15px;
        border: 1px solid #999;
        display: inline-block;
        border-radius: 30px;
    }
    &:hover {
        background-color: var(--primary-color);
        color: #fff;
        .desc {
            color: #fff;
        }
        svg path {
            fill: #fff;
        }
        .lnkTitle {
            color: #fff;
        }
    }
}
