
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.mainWrapper {
    min-height: calc(100vh - 58px);
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #d9e9f2 110.84%
    );
}
